<template>
    <div class="container">
		<!-- 设置登录密码 -->
		<!-- <common-head :title="$t('tba.changeLoginPass')" :is-return="true"></common-head> -->
		<van-nav-bar
		    :title="$t('Uset.SetEmail')"
		    left-arrow
		    @click-left="onClickLeft"
		    safe-area-inset-top
		    fixed
		    z-index="9999"
		/>
		<div style="padding-top: calc(env(safe-area-inset-top) + 0.88rem);">
			<div class="layoutBox bgWhite body1Style">
				<form-list :label="$t('tba.qingshuruyouxiang')">
					<div class="bd" slot="contslot">
						<input type="password" class="inp" v-model="emailcode" :placeholder="$t('tba.qingshuruyouxiangzhanghao')" />
					</div>
				</form-list>
				<form-list>
					<div class="bd" slot="contslot">
						<input type="number" class="inp" v-model="inputCode" :placeholder="$t('login.PleaseEnterVerificationCode')" />
						<div class="button" @click="onSendCode">
							<span v-if="codeSending" class="mainColor1">{{ theTime }}S</span>
							<span v-else class="mainColor1">{{ $t('sPhone.getCode') }}</span>
						</div>
					</div>
				</form-list>
				
				<div class="comBtnStyle" @click="bindEmail" style="margin-top: 7rem;">{{ $t('tba.quedingbangding') }}</div>
			</div>
		</div>
		
    </div>
</template>

<script>
	import formList from '../../components/formList';
    export default {
        name: "setThePassword",
        components: {
			formList,
		},
        data() {
            return {
                value: "",
                phone: "", //  用户手机号
                nation: "", // 国家区号
                // popupShow: false,
                // popupShow2: false,
                // popupShow3: false,
                showKeyboard: false,
                exchange_name: "",
                emailcode: "",
                inputCode: "",
                exchange_id: 0,
                article_id: 0,
                password: "", //交易密码
                password2: "",
                count: 0,
                list2: {},
                flag: false,
                ifhave: false,
                theTime: 60,
                vcode_method: "",
                show: false,
                paypwd: "",
                codeSending: false,
                paypwd2: "",
            };
        },
        created() {
            this.exchange_name = this.$route.query.exchange_name;
            this.exchange_id = this.$route.query.exchange_id;
            // this.getKey();
            this.initData();
            // this.getKey();
        },

        methods: {
            initData() {
                this.$post2("user/api/index/getMetaInfo", {})
                    .then((res) => {
                        this.phone = res.phone;
                        this.nation = res.nation;
                    })
                    .catch((e) => {
                        this.$toast.fail(e);
                    });
            },
            bindEmail() {
                if (!this.check()) {
                    return;
                }
                this.setNewEmail();
            },
			// 绑定邮箱
			setNewEmail() {
			    this.$post2("/User/Api/index/setNewEmail", {
			        email: this.emailcode,
					phone: this.phone,
			        code: this.inputCode,
			        sid: this.sid,
			    }).then((res) => {
			        console.log(res);
			        this.$toast(this.$t("tba.bindEmailSuccess"));
			        this.$router.go(-1);
			    });
			},

            // 获取验证码
            onSendCode() {
                if (this.codeSending) {
                    return;
                }
                this.theTime = 60;
                this.codeSending = true;
                this.doCountDown();
                // this.$post2('/security/user/authsmscode', {
                //     op: "opsecret",
                // }).then((res) => {
                //     console.log(res);
                //     this.sid = res.sid;
                // });
				this.$post2('/security/user/authsmscode', {
					phone: '15037915292',
					nation: '86',
					op: 'opsecret',
				}).then(res => {
					// console.log(res);
					this.sid = res.sid;
				});
            },
            // 又改成 一次定时器了 写的真烂...
            doCountDown() {
                if (this.theTime > 1) {
                    this.theTime--;
                    this.codeTimer = setTimeout(() => {
                        this.doCountDown();
                    }, 1000);
                } else {
                    this.codeSending = false;
                }
            },
            check() {
				if (this.emailcode == "") {
				    this.$toast(this.$t("sPay.PleaseEnterCorrectTransactionEmail"));
				    return false;
				}
                if (this.inputCode == "") {
                    this.$toast(this.$t("sPay.PleaseEnterCorrectVerificationCode"));
                    return false;
                }
                // if (this.loginpwd == "") {
                //   this.showAlert("请输入正确的登录密码");
                //   return false;
                // }

                return true;
            },
            onClickLeft() {
                this.$router.back(-1);
            },
        },
    };
</script>

<style scoped>
    .imgFontStyle {
        width: 0.4rem;
    }

    .divTopInChong {
        margin-top: 1.4rem;
    }

    .body1StyleDivBtn {
        border: 0px;
        border-radius: 5px;
        background: #e1c364;
        width: 2rem;
        margin-top: 0.3rem;
    }

    .body1StyleDivStyle {
        margin-top: 0.6rem;
    }

    .body1StyleSPan {
        color: #FF4B04;
    }

    .body1StylepFont {
        /* margin-top: 0.3rem; */
        font-size: 0.3rem;
		color: #333333;
    }

    /* @import "../../assets/css/puliceStyle.css"; */
    .body1StyleInputStyle {
        border: 0px;
        width: 60%;
    }

    .bodyStyleDivStyleP {
        margin-top: 0.3rem;
        margin-bottom: 0.3rem;
    }

    .bodyStyleDivStyle {
        border-bottom: 1px solid #fbfbfd;
        padding-bottom: 0.3rem;
    }

    .body1Style {
        margin-top: 0.2rem;
        min-height:98.8vh;
        border-top-left-radius: 0.32rem;
        border-top-right-radius: 0.32rem;
		padding: 0.3rem 0.4rem;
		font-family: PingFangSC-Regular;
    }

    .bottom1 {
        margin-top: 2rem;
    }

    .whole {
        background: #ffffff;
    }

    .title {
        background: #f6f8f9;
        height: 2rem;
        padding: 0.3rem;
    }

    .bottomBtnStyle {
        width: 100%;
        background: #e1c364;
        border-radius: 5px;
        border: 0px;
    }

    .titleGuanLiStyle {
        font-size: 0.4rem;
        padding-top: 1.5rem;
    }

    .titlePStyle {
        margin-bottom: 0.2rem;
        margin-top: 0.3rem;
        padding-top: 0.1rem;
        transform: rotate(180deg);
    }
	.button {
		border: none !important;
		font-size: 0.28rem;
	}
</style>
